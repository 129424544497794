import React from 'react';
import { useIntl } from 'react-intl';

import MissingInformation from '../MissingInformation';

import { ReactComponent as EditCVIcon } from 'assets/edit-candidate-cv-icon.svg';

import { ROLES } from 'constants/userConstants';

import './CvSkills.scss';

const CvSkills = ({
    skills,
    enterEditMode,
    enableEdit,
    displayMode
}) => {
    const intl = useIntl();

    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="cv-skills-wrapper">
                <div className="header">
                    <h1 className="title subheading-xxl color-dark-grey">
                        {intl.formatMessage({ id: "skills-text" })}
                    </h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('skills')}>
                            <EditCVIcon />
                        </div>
                    }
                </div>
                {skills?.length > 0
                    ?
                    <div className="skills-container">
                        {skills.map((s, idx) =>
                            <div className={`skill-box subheading-m color-grey ${isClient ? 'client' : ''}`} key={`${idx}-${s._id}`}>{s.name}</div>
                        )}
                    </div>
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
};

export default CvSkills;
