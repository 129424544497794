import moment from 'moment';

import { INTERVIEW_INVITATION_STATUSES } from "constants/interviewInvitationConstants";

export const getInterviewStatus = (interview) => {
    if (interview.status === INTERVIEW_INVITATION_STATUSES.APPROVED) {
        return moment(interview.interviewDate.date).isBefore(moment()) ? "Completed" : "Upcoming";
    }

    return interview.interviewTimeSlots.every(x => moment(x.date).isBefore(moment())) ? "Completed" : "Upcoming"
};
