import React from 'react';
import { ErrorMessage } from 'formik';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { MenuItem, TextField } from '@material-ui/core';

import SelectTechStack from 'components/CandidateProfileJourney/Steps/UploadResumeWrapper/DialogsCV/SelectTechStack';

import { ReactComponent as RadioButtonIcon } from 'assets/radio-button.svg';
import { ReactComponent as RadioButtonCheckedIcon } from 'assets/radio-button-checked-orange.svg';

import { CANDIDATE_SALARY_CURRENCIES } from 'constants/currenciesConstant';
import { CANDIDATE_EXPERIENCE_OPTIONS } from 'components/CandidateProfileJourney/Steps/YourProfileWrapper/YourProfileConstants';

import './IdealCandidateFormView.scss';

const IdealCandidateFormView = ({
    values,
    handleChange,
    errors,
    touched,
    errorClassName,
    setFieldValue,
    handleChangeMandatorySkills,
    handleDeleteMandatorySkill,
    addNewMandatorySkill,
    handleChangeNiceToHaveSkills,
    handleDeleteNiceToHaveSkill,
    addNewNiceToHaveSkill,
}) => (
    <form className="ideal-candidate-dialog-form" autoComplete="off">
        <div className="form-row">
            <p className="subheading-xl color-grey dialog-label-text">How many years of professional experience?</p>
            <RadioGroup
                value={values.yearsOfExperience}
                name={"yearsOfExperience"}
                className={`options-card-labels-wrapper ${(errors.yearsOfExperience && touched.yearsOfExperience ? errorClassName || 'error' : '')}`}
                onChange={handleChange}
            >
                {CANDIDATE_EXPERIENCE_OPTIONS.map(option => (
                    <FormControlLabel
                        className={`options-card-label ${values.yearsOfExperience === option.value ? 'selected' : ''}`}
                        key={option.value}
                        value={option.value}
                        control={<Radio disableRipple className="radio-btn" icon={<RadioButtonIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
                        label={option.title}
                        labelPlacement="start"
                    />
                ))}
                <ErrorMessage
                    name={"yearsOfExperience"}
                    component={() => (
                        <div className="invalid-field-wrapper">
                            <ErrorRoundedIcon className="error-icon" fontSize="small" />
                            <span>The field is required. Please select one option.</span>
                        </div>
                    )}
                />
            </RadioGroup>
        </div>
        <div className="form-row">
            <p className="subheading-xl color-grey dialog-label-text">What is your maximum budget?</p>
            <div className="form-cols">
                <div className="col-1of2">
                    <TextField
                        className={`${touched.maximumBudget && errors.maximumBudget ? "with-error" : "base-input"}`}
                        type="number"
                        variant="outlined"
                        label="Enter Value"
                        InputLabelProps={{ shrink: true }}
                        value={values.maximumBudget}
                        name="maximumBudget"
                        fullWidth={true}
                        onChange={(event) => {
                            setFieldValue('maximumBudget', Number(event.target.value));
                        }}
                        error={touched.maximumBudget && Boolean(errors.maximumBudget)}
                        helperText={touched.maximumBudget && errors.maximumBudget}
                    />
                </div>

                <div className="col-1of2">
                    <TextField
                        className={`${touched.currency && errors.currency ? "with-error" : "base-input"}`}
                        select
                        label="Choose Currency"
                        InputLabelProps={{ shrink: true }}
                        value={values.currency}
                        variant="outlined"
                        onChange={handleChange}
                        name="currency"
                        id="currency"
                        fullWidth={true}
                        error={touched.currency && Boolean(errors.currency)}
                        helperText={touched.currency && errors.currency}
                    >
                        {CANDIDATE_SALARY_CURRENCIES.map((currency) => {
                            return (
                                <MenuItem key={currency.VALUE} value={currency.LABEL}>
                                    {currency.LABEL}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </div>
            </div>
        </div>
        <div className="form-row">
            <p className="subheading-xl color-grey dialog-label-text">Mandatory skills</p>
            <SelectTechStack
                label="Stack"
                handleInputChange={handleChangeMandatorySkills.bind(null, values, setFieldValue)}
                handleOnDeleteSkill={handleDeleteMandatorySkill.bind(null, values, setFieldValue)}
                addNewSkill={addNewMandatorySkill.bind(null, values, setFieldValue)}
                technologies={values.mandatorySkills}
                error={errors.mandatorySkills && touched.mandatorySkills ? errors.mandatorySkills : ''}
            />
        </div>
        <div className="form-row">
            <p className="subheading-xl color-grey dialog-label-text">Nice to have</p>
            <SelectTechStack
                label="Stack"
                handleInputChange={handleChangeNiceToHaveSkills.bind(null, values, setFieldValue)}
                handleOnDeleteSkill={handleDeleteNiceToHaveSkill.bind(null, values, setFieldValue)}
                addNewSkill={addNewNiceToHaveSkill.bind(null, values, setFieldValue)}
                technologies={values.niceToHaveSkills}
                error={errors.niceToHaveSkills && touched.niceToHaveSkills ? errors.niceToHaveSkills : ''}
            />
        </div>
        <div className="form-row">
            <p className="subheading-xl color-grey dialog-label-text">How many people are you looking to hire for this position?</p>
            <TextField
                className={`${touched.peopleToHire && errors.peopleToHire ? "with-error" : "base-input"}`}
                type="number"
                variant="outlined"
                label="Quantity"
                InputLabelProps={{ shrink: true }}
                value={values.peopleToHire}
                name="peopleToHire"
                fullWidth={true}
                onChange={(event) => {
                    setFieldValue('peopleToHire', Number(event.target.value));
                }}
                error={touched.peopleToHire && Boolean(errors.peopleToHire)}
                helperText={touched.peopleToHire && errors.peopleToHire}
            />
        </div>
    </form>
);

export default IdealCandidateFormView;
