import {
    FETCH_JOB_SEARCHES_SUCCESS,
    FETCH_JOB_SEARCH_SUCCESS,
    INTERVIEW_INVITATION_FETCH_SUCCESS,
    JOB_SEARCH_CREATE_SUCCESS,
    JOB_SEARCH_UPDATE_SUCCESS,
    JOB_SEARCH_DELETE_SUCCESS,
    FETCH_JOB_SEARCHES_INTERVIEWS_SUCCESS,
    FETCH_JOB_SEARCHES_LIKED_CANDIDATES_SUCCESS,
} from './actionTypes';

import { showNotification } from './notificationActions';

import jobSearchService from 'services/jobSearchService';
import { authorizedShowNotification } from './actionHelpers';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { JOB_SEARCH_MESSAGES } from 'constants/messageConstants';

const fetchJobSearchesSuccess = (payload) => ({
    type: FETCH_JOB_SEARCHES_SUCCESS,
    payload,
});

const fetchJobSearchSuccess = (payload) => ({
    type: FETCH_JOB_SEARCH_SUCCESS,
    payload,
});

const deleteJobSearchSuccess = (payload) => ({
    type: JOB_SEARCH_DELETE_SUCCESS,
    payload,
});

const createJobSearchSuccess = (payload) => ({
    type: JOB_SEARCH_CREATE_SUCCESS,
    payload,
});

const updateJobSearchSuccess = (payload) => ({
    type: JOB_SEARCH_UPDATE_SUCCESS,
    payload,
});

const fetchInterviewInvitationSuccess = (payload) => ({
    type: INTERVIEW_INVITATION_FETCH_SUCCESS,
    payload,
});

const updateJobSearchStatusSuccess = (payload) => ({
    type: JOB_SEARCH_UPDATE_SUCCESS,
    payload,
});

const fetchJobSearchesInterviewsSuccess = (payload) => ({
    type: FETCH_JOB_SEARCHES_INTERVIEWS_SUCCESS,
    payload,
});

const fetchJobSearchesLikedCandidatesSuccess = (payload) => ({
    type: FETCH_JOB_SEARCHES_LIKED_CANDIDATES_SUCCESS,
    payload,
});

export const fetchJobSearches = (agencyId, clientId) => async dispatch => {
    try {
        const result = await jobSearchService.fetchJobSearches(agencyId, clientId);

        dispatch(fetchJobSearchesSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.FETCH_JOB_SEARCH_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchJobSearch = (agencyId, clientId, jobSearchId) => async dispatch => {
    try {
        const result = await jobSearchService.fetchJobSearch(agencyId, clientId, jobSearchId);

        dispatch(fetchJobSearchSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.FETCH_JOB_SEARCH_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const deleteJobSearch = (agencyId, clientId, jobSearchId) => async dispatch => {
    try {
        const result = await jobSearchService.deleteJobSearch(agencyId, clientId, jobSearchId);

        dispatch(deleteJobSearchSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.FETCH_JOB_SEARCH_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const createJobSearch = (agencyId, clientId, jobSearchData) => async dispatch => {
    try {
        const result = await jobSearchService.createJobSearch(agencyId, clientId, jobSearchData);

        dispatch(createJobSearchSuccess(result));
        dispatch(showNotification(JOB_SEARCH_MESSAGES.CREATE_JOB_SEARCH_SUCCESS, NOTIFICATION_TYPES.SUCCESS));

        return result._id;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.CREATE_JOB_SEARCH_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const searchMatchingCandidates  = (agencyId, clientId, jobSearchId) => async dispatch => {
    try {
        const result = await jobSearchService.searchMatchingCandidates(agencyId, clientId, jobSearchId);

        dispatch(updateJobSearchSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.CREATE_JOB_SEARCH_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const likeCandidate  = (agencyId, clientId, jobSearchId, candidateId) => async dispatch => {
    try {
        const result = await jobSearchService.likeCandidate(agencyId, clientId, jobSearchId, candidateId);

        dispatch(updateJobSearchSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.LIKE_CANDIDATE_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchJobSearchCandidateInterview  = (agencyId, clientId, jobSearchId, candidateId) => async dispatch => {
    try {
        const result = await jobSearchService.getJobSearchCandidateInterview(agencyId, clientId, jobSearchId, candidateId);

        dispatch(fetchInterviewInvitationSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.FETCH_CANDIDATE_INTERVIEW_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const updateJobSearchStatus  = (agencyId, clientId, jobSearchId, status) => async dispatch => {
    try {
        const result = await jobSearchService.updateJobSearchStatus(agencyId, clientId, jobSearchId, status);

        dispatch(updateJobSearchStatusSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.UPDATE_JOB_SEARCH_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchJobSearchesInterviews  = (agencyId, clientId) => async dispatch => {
    try {
        const result = await jobSearchService.fetchJobSearchesInterviews(agencyId, clientId);

        dispatch(fetchJobSearchesInterviewsSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.UPDATE_JOB_SEARCH_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchJobSearchesLikedCandidates  = (agencyId, clientId) => async dispatch => {
    try {
        const result = await jobSearchService.fetchJobSearchesLikedCandidates(agencyId, clientId);

        dispatch(fetchJobSearchesLikedCandidatesSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.FETCH_LIKED_CANDIDATES_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};

export const markViewedCandidate = (agencyId, clientId, jobSearchId, candidateId) => async dispatch => {
    try {
        await jobSearchService.markViewedCandidate(agencyId, clientId, jobSearchId, candidateId);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_SEARCH_MESSAGES.UPDATE_JOB_SEARCH_FAILED, NOTIFICATION_TYPES.ERROR))
    }
};
