import { FETCH_JOB_SEARCHES_INTERVIEWS_SUCCESS, FETCH_JOB_SEARCHES_LIKED_CANDIDATES_SUCCESS, FETCH_JOB_SEARCHES_SUCCESS, FETCH_JOB_SEARCH_SUCCESS, JOB_SEARCH_CREATE_SUCCESS, JOB_SEARCH_DELETE_SUCCESS, JOB_SEARCH_UPDATE_SUCCESS } from 'actions/actionTypes';

const jobSearchInitialState = {
    _id: null,
    jobType: "",
    position: "",
    aboutTheCompany: "",
    aboutTheJob: "",
    yearsOfExperience: "",
    maximumBudget: 0,
    currency: "",
    peopleToHire: 0,
    clientId: "",
    agencyId: "",
    creationDate: "",
    matchingCandidatesCount: 0,
    niceToHaveSkills: [],
    mandatorySkills: [],
    matchingCandidates: [],
    countries: [],
    likedCandidates: [],
    likedCandidatesInfo: [],
    imageData: {},
};

const jobSearchesInitialState = {
    list: [],
    selected: { ...jobSearchInitialState },
    interviews: [],
    likedCandidates: [],
};

const jobSearch = (state = jobSearchInitialState, action) => {
    if (state._id && state._id !== action.payload._id) {
        return state;
    }

    switch (action.type) {
        case JOB_SEARCH_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

const jobSearches = (state = jobSearchesInitialState, action) => {
    switch (action.type) {
        case JOB_SEARCH_CREATE_SUCCESS:
            return { ...state, list: [...state.list, action.payload] };
        case FETCH_JOB_SEARCHES_SUCCESS:
            return { ...state, list: action.payload };
        case FETCH_JOB_SEARCH_SUCCESS:
            return { ...state, selected: action.payload };
        case JOB_SEARCH_UPDATE_SUCCESS:
            return { ...state, list: state.list.map(x => jobSearch(x, action)), selected: jobSearch(state.selected, action) };
        case JOB_SEARCH_DELETE_SUCCESS:
            return { ...state, list: state.list.filter(x => x._id !== action.payload._id), selected: jobSearchesInitialState };
        case FETCH_JOB_SEARCHES_INTERVIEWS_SUCCESS:
            return { ...state, interviews: action.payload };
        case FETCH_JOB_SEARCHES_LIKED_CANDIDATES_SUCCESS:
            return { ...state, likedCandidates: action.payload };
        default:
            return state;
    }
};

export default jobSearches;

export const getJobSearches = state => state.list;
export const getJobSearch = state => state.selected;
export const getJobSearchesInterviews = state => state.interviews;
export const getJobSearchesLikedCandidates = state => state.likedCandidates;
