import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { Avatar } from '@material-ui/core';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { CONTINENTAL_TIME_FORMAT, DATE_FORMAT_WITH_SLASH_SHORT_YEAR } from 'constants/commonConstants';
import { INTERVIEW_INVITATION_STATUSES } from 'constants/interviewInvitationConstants';

import { ReactComponent as CalendarIcon } from 'assets/interviews-calendar-icon.svg';
import { ReactComponent as ClockIcon } from 'assets/icons-clock.svg';
import { ReactComponent as LinkIcon } from 'assets/icons-link.svg';
import { ReactComponent as LocationIcon } from 'assets/icons-location.svg';
import { ReactComponent as RightIcon } from 'assets/arrow-right.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/check-circle.svg';

import { getStateAgencyName } from 'utils/helpers';
import { getInterviewStatus } from 'utils/jobSearchesUtils';

import defaultAvatar from 'assets/default-avatar.svg';

import './InterviewDetails.scss';

const InterviewDetails = ({
    interview,
    position,
    matchingCandidates,
    openInviteDialogHandler,
    jobSearchId,
}) => {
    const history = useHistory();

    const getInterviewDateTimeDetails = (interview) => {
        if (interview.status === INTERVIEW_INVITATION_STATUSES.APPROVED) {
            return [{ ...interview.interviewDate }];
        }

        return interview.interviewTimeSlots || [];
    };

    return (
        <div className="job-search-upcoming-interview-wrapper">
            <div className="job-search-upcoming-interviews-interview-details">
                <div className="job-search-upcoming-interviews-interview-header">
                    <div className="job-search-upcoming-interviews-interview-title">
                        <p className="heading-xs color-dark-grey">{position}</p>
                        <p className="subheading-xs color-grey">{interview.status === INTERVIEW_INVITATION_STATUSES.APPROVED ? "The candidate confirmed interview date and time." : "You have already sent an interview invitation to this candidate."}</p>
                    </div>
                    <div className="job-search-upcoming-interviews-interview-status">
                        <div className={`job-search-upcoming-interviews-interview-status-box ${getInterviewStatus(interview).toLowerCase()}`}>{getInterviewStatus(interview)}</div>
                    </div>
                </div>
                <hr className="custom-divider" />
                <div className="job-search-upcoming-interviews-candidate-info-wrapper">
                    <div className="job-search-upcoming-interviews-candidate-info-content">
                        <Avatar src={interview.candidateInfo.imgUrl || defaultAvatar} />
                        <div className="job-search-upcoming-interviews-candidate-name-position">
                            <p className="heading-s color-dark-grey">{interview.candidateInfo.firstName} {interview.candidateInfo.lastName}</p>
                            <p className="subheading-xxl color-grey">{interview.candidateInfo.position}</p>
                        </div>
                    </div>
                    <div className="job-search-upcoming-interviews-candidate-matching-score-info">
                        <p className="heading-m color-dark-grey">{`${matchingCandidates.find(x => interview.candidateInfo._id === x.candidateId)?.matchingScore || 0}%`}</p>
                        <p className="subheading-xxl color-grey">Matching Score</p>
                    </div>
                </div>
                <div className="job-search-upcoming-interviews-additional-details">
                    <div className="job-search-upcoming-interviews-additional-details-location">
                        {interview.location.startsWith("https://") ? <LinkIcon /> : <LocationIcon />}
                        {interview.location.startsWith("https://") ? <a href={interview.location} className="link subheading-l color-deep-orange">Interview link</a> : <p className="subheading-xxl color-grey">{interview.location}</p>}
                    </div>
                    <p onClick={() => history.push(`/${getStateAgencyName()}/talent-searches/${jobSearchId}/search-results?candidateId=${interview.candidateInfo._id}`)} className="view-all-btn subheading-l color-deep-orange">View CV <RightIcon /></p>
                </div>
            </div>
            <div className="job-search-upcoming-interviews-interview-time-details">
                {interview.status === INTERVIEW_INVITATION_STATUSES.APPROVED &&
                    <div className="job-search-upcoming-interviews-interview-accepted">
                        <CheckCircleIcon />
                        <p className="subheading-xs color-grey">Interview has been scheduled</p>
                    </div>
                }
                {getInterviewDateTimeDetails(interview).map((slot, idx) => (
                    <div className={`job-search-upcoming-interviews-interview-slot-details ${interview.status === INTERVIEW_INVITATION_STATUSES.APPROVED && interview.interviewDate.time === slot.time ? "approved" : ""}`}>
                        <p className="subheading-s color-dark-grey">Slot {idx + 1}</p>
                        <div className="job-search-upcoming-interviews-interview-date-time-details">
                            <div className="date-details">
                                <CalendarIcon />
                                <p className="subheading-l color-grey">{moment(slot.date).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR)}</p>
                            </div>
                            <div className="time-details">
                                <ClockIcon />
                                <p className="subheading-l color-grey">{moment(slot.time).format(CONTINENTAL_TIME_FORMAT)}-{moment(slot.time).add(1, 'hour').format(CONTINENTAL_TIME_FORMAT)}</p>
                            </div>
                        </div>
                    </div>
                ))}
                {interview.status !== INTERVIEW_INVITATION_STATUSES.APPROVED &&
                    <PrimaryButton
                        className="dark reschedule-btn"
                        text="Reschedule"
                        handleClick={() => {
                            const { candidateInfo, ...rest } = interview;
                            openInviteDialogHandler(candidateInfo._id, rest);
                        }}
                    />
                }
            </div>
        </div>
    )
};

export default InterviewDetails;
