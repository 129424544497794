import React from 'react';

import { CANDIDATE_EXPERIENCE_OPTIONS } from 'components/CandidateProfileJourney/Steps/YourProfileWrapper/YourProfileConstants';


const JobSearchDropdown = ({
    jobSearch
}) => {
    const convertRequiredYearsOfExperienceToText = (requiredYearsOfExperience) => {
        switch (requiredYearsOfExperience) {
            case CANDIDATE_EXPERIENCE_OPTIONS[0].value:
                return "Less than one year of experiences";
            case CANDIDATE_EXPERIENCE_OPTIONS[1].value:
                return "Between one and two years of experience";
            case CANDIDATE_EXPERIENCE_OPTIONS[2].value:
                return "Between two and three years of experience";
            case CANDIDATE_EXPERIENCE_OPTIONS[3].value:
                return "Between three and five years of experience";
            case CANDIDATE_EXPERIENCE_OPTIONS[4].value:
                return "More than five years of experience";
            default:
                return "";
        }
    };

    return (
        <div className="job-searches-job-card-details">
            <div className="job-searches-job-details-section">
                <p className="subheading-xxl color-dark-grey">Experience needed</p>
                <hr className="custom-divider" />
                <p className="subheading-m color-grey">{convertRequiredYearsOfExperienceToText(jobSearch.yearsOfExperience)}</p>
            </div>
            <div className="job-searches-job-details-section">
                <p className="subheading-xxl color-dark-grey">Maximum budget</p>
                <hr className="custom-divider" />
                <p className="subheading-m color-grey">{jobSearch.maximumBudget} {jobSearch.currency}</p>
            </div>
            <div className="job-searches-job-details-section">
                <p className="subheading-xxl color-dark-grey">Mandatory skills</p>
                <hr className="custom-divider" />
                <div className="job-searches-job-details-skills">
                    {jobSearch.mandatorySkills?.map(x => <p className="skill-box subheading-m color-dark-grey">{x}</p>)}
                </div>
            </div>
            <div className="job-searches-job-details-section">
                <p className="subheading-xxl color-dark-grey">Nice to have</p>
                <hr className="custom-divider" />
                <div className="job-searches-job-details-skills">
                    {jobSearch.niceToHaveSkills?.map(x => <p className="skill-box subheading-m color-dark-grey">{x}</p>)}
                </div>
            </div>
            <div className="job-searches-job-details-section">
                <p className="subheading-xxl color-dark-grey">How many people</p>
                <hr className="custom-divider" />
                <p className="subheading-m color-grey">{jobSearch.peopleToHire}</p>
            </div>
            <div className="job-searches-job-details-section">
                <p className="subheading-xxl color-dark-grey">Job type</p>
                <hr className="custom-divider" />
                <p className="subheading-m color-grey">{jobSearch.jobType}</p>
            </div>
            <div className="job-searches-job-details-section">
                <p className="subheading-xxl color-dark-grey">Country</p>
                <hr className="custom-divider" />
                <p className="subheading-m color-grey">{jobSearch.countries?.join(", ")}</p>
            </div>
            <div className="job-searches-job-details-section">
                <p className="subheading-xxl color-dark-grey">About the company</p>
                <hr className="custom-divider" />
                <p className="subheading-m color-grey">{jobSearch.aboutTheCompany}</p>
            </div>
            <div className="job-searches-job-details-section">
                <p className="subheading-xxl color-dark-grey">About the job</p>
                <hr className="custom-divider" />
                <p className="subheading-m color-grey">{jobSearch.aboutTheJob}</p>
            </div>
        </div>
    )
};

export default JobSearchDropdown;
