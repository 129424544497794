import React from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import moment from 'moment';

import MissingInformation from '../MissingInformation';

import { ReactComponent as EditCVIcon } from 'assets/edit-candidate-cv-icon.svg';

import { ROLES } from 'constants/userConstants';
import { MONTH_NAME_FORMAT } from 'constants/timesFormat';

import { ReactComponent as DateIcon } from 'assets/cv-date-icon.svg';

import './CvExperience.scss';

const CvExperience = ({
    projects,
    enterEditMode,
    enableEdit,
    displayMode,
}) => {
    const intl = useIntl();

    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="cv-experience-wrapper">
                <div className="header">
                    <h1 className="title subheading-xxl color-dark-grey">{intl.formatMessage({ id: "experience" })}</h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('experience')}>
                            <EditCVIcon />
                        </div>
                    }
                </div>

                {projects?.length > 0 ?
                    projects?.map(project =>
                        <div className="experience-subsection" key={project._id}>
                            {
                                isMobile
                                    ?
                                    <div className="experience-row">
                                        <div>
                                            <p className="row-heading">{project.name}</p>
                                            <div className="dates-wrapper">
                                                {project.startDate
                                                    && <>
                                                        
                                                        <p className="dates">
                                                            <span>{moment(project.startDate).format(MONTH_NAME_FORMAT)}</span>
                                                            <span> - </span>
                                                            {project.currentlyWork
                                                                ? <span>ongoing</span>
                                                                : <span>{moment(project.endDate).format(MONTH_NAME_FORMAT)}</span>}
                                                        </p>
                                                    </>}
                                            </div>
                                        </div>
                                        <p className="row-subheading">{project.position}
                                            {project.client && <span> at {project.client}</span>}
                                        </p>
                                    </div>
                                    :
                                    <div className="experience-row">
                                        <div>
                                            <p className="row-heading subheading-l color-dark-grey">{project.name}</p>
                                            <p className="row-subheading subheading-m color-grey">{project.position}
                                                {project.client && <span className="subheading-m color-grey"> at {project.client}</span>}
                                            </p>
                                        </div>
                                        <div className="dates-wrapper">
                                            {project.startDate
                                                && <>
                                                    <DateIcon />
                                                    <p className="dates">
                                                        <span className="subheading-m color-grey">{moment(project.startDate).format(MONTH_NAME_FORMAT)}</span>
                                                        <span className="subheading-m color-grey"> - </span>
                                                        {project.currentlyWork
                                                            ? <span className="subheading-m color-grey">ongoing</span>
                                                            : <span className="subheading-m color-grey">{moment(project.endDate).format(MONTH_NAME_FORMAT)}</span>}
                                                    </p>
                                                </>}
                                        </div>
                                    </div>

                            }

                            {project.description &&
                                <p className="description subheading-m color-grey">
                                    {project.description}
                                </p>
                            }
                            {project.technologies.length > 0 &&
                                <div className="skills-container experience-skills-container">
                                    {project.technologies.map(technology =>
                                        <div className={`skill-box subheading-m color-grey ${isClient ? "client" : ""}`} key={technology}>{technology}</div>
                                    )}
                                </div>
                            }
                        </div>)
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
}

export default CvExperience;
