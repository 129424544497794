import moment from 'moment';

import { DATE_FORMAT_WITH_SLASH } from 'constants/commonConstants';

export const sortInterviewsByDates = (interviews) => {
    const sortedInterview = [...interviews];

    sortedInterview.sort((a, b) => {
        // Check if interviews have 'interviewDate'
        const aConfirmed = a.hasOwnProperty('interviewDate');
        const bConfirmed = b.hasOwnProperty('interviewDate');

        // If both are confirmed or both are not confirmed, sort by 'interviewTimeSlots'
        if (aConfirmed && bConfirmed) {
            const aDate = new Date(a.interviewDate.date);
            const bDate = new Date(b.interviewDate.date);
            return aDate - bDate;
        }

        if (aConfirmed) {
            return -1;
        }

        if (bConfirmed) {
            return 1;
        }

        // If both are not confirmed, sort by the soonest date in 'interviewTimeSlots'
        const aEarliestSlot = new Date(Math.min(...a.interviewTimeSlots.map(slot => new Date(slot.date))));
        const bEarliestSlot = new Date(Math.min(...b.interviewTimeSlots.map(slot => new Date(slot.date))));

        return aEarliestSlot - bEarliestSlot;
    });

    return sortedInterview;
};

export const findEarliestDate = (interviews) => {
    let earliestDate = null;

    interviews.forEach(interview => {
        // If interview is confirmed, use 'interviewDate'
        if (interview.hasOwnProperty('interviewDate')) {
            const interviewDate = new Date(interview.interviewDate.date);
            if (!earliestDate || interviewDate < earliestDate) {
                earliestDate = interviewDate;
            }
        } else {
            // If not confirmed, find the earliest date in 'interviewTimeSlots'
            const earliestSlot = new Date(Math.min(...interview.interviewTimeSlots.map(slot => new Date(slot.date))));
            if (!earliestDate || earliestSlot < earliestDate) {
                earliestDate = earliestSlot;
            }
        }
    });

    return earliestDate ? moment(earliestDate).format(DATE_FORMAT_WITH_SLASH) : null;
};

export const getEarliestDatesForEachInterview = (interviews) => {
    return interviews.map(interview => {
        if (interview.hasOwnProperty('interviewDate')) {
            // If interview is confirmed, return 'interviewDate'
            return moment(interview.interviewDate.date).format(DATE_FORMAT_WITH_SLASH);
        } else {
            // If not confirmed, find the earliest date in 'interviewTimeSlots'
            const earliestSlot = new Date(Math.min(...interview.interviewTimeSlots.map(slot => new Date(slot.date))));
            return moment(earliestSlot).format(DATE_FORMAT_WITH_SLASH);
        }
    }).filter((value, index, self) => self.indexOf(value) === index);
};

export const getInterviewsOnDate = (interviews, selectedDate) => {
    // Convert DD/MM/YYYY to YYYY-MM-DD format
    const [day, month, year] = selectedDate.split('/');
    const formattedDate = `${year}-${month}-${day}T00:00:00Z`;
    
    const selectedDateStart = new Date(formattedDate);
    const selectedDateEnd = new Date(formattedDate);
    selectedDateEnd.setDate(selectedDateEnd.getDate() + 1);

    return interviews.filter(interview => {
        // Check if the interview is confirmed and matches the selected date
        if (interview.hasOwnProperty('interviewDate')) {
            const interviewDate = new Date(interview.interviewDate.date);
            if (interviewDate >= selectedDateStart && interviewDate < selectedDateEnd) {
                return true;
            }
        }

        // Check if any of the time slots match the selected date
        return interview.interviewTimeSlots.some(slot => {
            const slotDate = new Date(slot.date);
            return slotDate >= selectedDateStart && slotDate < selectedDateEnd;
        });
    });
};
