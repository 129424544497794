import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchJobSearch } from 'actions/jobSearchActions';
import { createInterviewInvitation, rescheduleInterview } from 'actions/interviewInvitationActions';

import { getAgencyId, getClientId, getClientMenuInformation, getJobSearch, getUserId } from 'reducers';

import JobSearch from 'components/JobSearches/JobSearch';
import JobSearchEmptySection from 'components/JobSearchDetails/JobSearchEmptySection';
import JobSearchSavedCandidates from './JobSearchSavedCandidates';
import InterviewInvitePopup from 'components/Shared/InterviewInvitePopup';
import JobSearchUpcomingInterviews from './JobSearchUpcomingInterviews';

import { ROLES } from 'constants/userConstants';
import { INTERVIEW_INVITATION_CONTRIBUTORS, INTERVIEW_INVITATION_STATUSES } from 'constants/interviewInvitationConstants';

import { ReactComponent as BackArrowIcon } from 'assets/arrow-left.svg';
import { ReactComponent as NoSavedTalentsIcon } from 'assets/heart-icon.svg';
import { ReactComponent as NoUpcomingInterviewsIcon } from 'assets/headphones-icon.svg';

import './JobSearchDetails.scss';

const JobSearchDetails = ({
    history,
    match,
    agencyId,
    userId,
    clientId,
    clientName,
    fetchJobSearch,
    jobSearchDetails,
    createInterviewInvitation,
    rescheduleInterview,
}) => {
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);
    const [interviewData, setInterviewData] = useState({});

    useEffect(() => {
        const jobSearchId = match.params.id;
        fetchJobSearch(agencyId, clientId, jobSearchId);
    }, [match.params.id]);

    const handleOpenInviteDialog = (candidateId) => {
        setSelectedCandidateId(candidateId);
        setIsInviteDialogOpen(true);
    };

    const handleOpenInviteDialogWithInterview = (candidateId, interviewData) => {
        setInterviewData(interviewData);
        setSelectedCandidateId(candidateId);
        setIsInviteDialogOpen(true);
    };

    const handleCloseInviteDialog = () => {
        setSelectedCandidateId(null);
        setInterviewData({});
        setIsInviteDialogOpen(false);
    };

    const getAllLikedCandidates = (jobSearchDetails) => {
        return jobSearchDetails.likedCandidatesInfo?.map(x => ({
            ...x,
            matchingScore: jobSearchDetails.matchingCandidates.find(y => y.candidateId === x._id)?.matchingScore || 0,
            hasInterviews: !!jobSearchDetails.interviewInvitations.find(y => y.participant === x._id)
        }));
    };

    const handleInviteToInterview = (values) => {
        const { _id, clientNote, interviewTimeSlots, ...restData } = values;
        const interviewer = userId;

        const additionalData = {
            dateAdded: moment.utc().format(),
            status: INTERVIEW_INVITATION_STATUSES.PENDING,
            proposedBy: INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER,
            interviewer: interviewer,
            participant: selectedCandidateId,
            jobSearchId: match.params.id,
            interviewTimeSlots: interviewTimeSlots.map(slot => {
                const date = slot.date.toISOString().split("T")[0];
                const time = slot.time.toISOString().split("T")[1];

                return {
                    date: `${date}T${time}`,
                    time: `${date}T${time}`
                }
            })
        };

        if (Object.keys(interviewData).length > 0) {
            rescheduleInterview(agencyId, interviewData._id, { ...restData, ...additionalData, note: clientNote }).then(() => fetchJobSearch(agencyId, clientId, match.params.id));
        } else {
            createInterviewInvitation(agencyId, { ...restData, ...additionalData, note: clientNote }).then(() => fetchJobSearch(agencyId, clientId, match.params.id));
        }
    };

    return (
        <div className="job-search-details-wrapper">
            <div className="job-search-details-header">
                <BackArrowIcon className="job-search-details-back-arrow" onClick={() => history.goBack()} />
                <div className="job-search-details-title">
                    <p className="heading-s color-dark-grey">Position Details / {jobSearchDetails.position}</p>
                    <p className="subheading-l color-grey">{clientName}</p>
                </div>
            </div>
            <hr className="custom-divider" />
            <JobSearch
                jobSearch={jobSearchDetails}
                jobSearchId={jobSearchDetails._id}
                isLoadingCandidateSearch={false}
                isDetailsPage
            />
            <p className="subheading-xxl color-grey">Saved Talents: {jobSearchDetails.likedCandidates?.length || 0}</p>
            {(!jobSearchDetails.likedCandidates || jobSearchDetails.likedCandidates?.length === 0) ?
                <JobSearchEmptySection icon={<NoSavedTalentsIcon />} title="You didn't save any candidates" description="But you don't need an excuse to have some cake." /> :
                <JobSearchSavedCandidates openDialogHandler={handleOpenInviteDialog} likedCandidates={getAllLikedCandidates(jobSearchDetails)} />
            }
            <p className="subheading-xxl color-grey">Interviews: {jobSearchDetails.interviewInvitations?.length || 0}</p>
            {!jobSearchDetails.interviewInvitations || jobSearchDetails.interviewInvitations.length === 0 ?
                <JobSearchEmptySection icon={<NoUpcomingInterviewsIcon />} title="No upcoming interviews" description="But you don't need an excuse to have some cake." /> :
                <JobSearchUpcomingInterviews 
                    openInviteDialogHandler={handleOpenInviteDialogWithInterview}
                    matchingCandidates={jobSearchDetails.matchingCandidates}
                    position={jobSearchDetails.position}
                    interviews={jobSearchDetails.interviewInvitations}
                    jobSearchId={jobSearchDetails._id}
                />
            }

            <InterviewInvitePopup
                displayMode={ROLES.CLIENT}
                dialogTitle="Invitation for interview"
                isWithNote={true}
                isDialogOpen={isInviteDialogOpen}
                isLocationDisabled={false}
                interviewInvitation={interviewData}
                handleCloseDialog={handleCloseInviteDialog}
                handelInterviewInvitationSubmit={handleInviteToInterview}
            />
        </div>
    )
};

const mapStateToProps = state => ({
    jobSearchDetails: getJobSearch(state),
    clientName: getClientMenuInformation(state).name,
    agencyId: getAgencyId(state),
    clientId: getClientId(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    fetchJobSearch,
    createInterviewInvitation,
    rescheduleInterview,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobSearchDetails);
